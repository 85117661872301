import { useMatchesData } from "./useMatches.js";

export function useAgency(agency) {
  const rId = useMatchesData("routes/r/$rId", false);
  const mId = useMatchesData("routes/m/$mId", false);
  const newFacility = useMatchesData("routes/new/$facility", false);

  if (rId) {
    agency = rId.availabilityRequest?.facility?.agency;
  } else if (mId) {
    agency = mId.availabilityMatch.availabilityRequest?.facility?.agency;
  } else if (newFacility) {
    agency = newFacility.facility?.agency;
  }

  if (!agency) {
    throw new Error(
      `"agency" field not found on 'r/$rId' or 'm/$mId' or 'new/$facility'. Double check the loader`,
    );
  }
  return agency;
}

export function useAgencyValue({ base, ...overrides } = {}, _agency) {
  if (typeof base === "undefined") {
    throw new Error('"base" value is required');
  }

  const agency = useAgency(_agency);

  const scanner = agency.scanner; // use scanner since it's a validated enum
  const override = overrides[scanner];
  const value = override ? override : base;
  return typeof value === "function" ? value() : value;
}
